import {  ConfigProvider } from 'antd';
import { Routes, Route} from "react-router-dom";
import HomePage from "./pages/homePage";

import "./App.css";

function App() { 
  return ( 
<ConfigProvider
            theme={{
                token: {
                    fontFamily: 'proxima_nova_rgregular',
                    colorPrimary:'#26F8FF',
                  
                },
                components: {
                  Tabs: {
                  itemColor:"#ffffff"
                  },
            }
          }}
        >
  <Routes>
    <Route path="/" element={<HomePage />}/>


</Routes>
  </ConfigProvider>

  )
}

export default App;
