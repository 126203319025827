import React, { useState, useEffect } from 'react';
import DiscoverComponent from '../components/DiscoverComponent';
import axios from 'axios';
import { Row } from 'antd';
import dataUnits from './data.json';
import { Spin } from 'antd';

let newUnitSceneData = [];

function HomePage() {

    const[loading,setLoading] = useState(true);


    useEffect(()=>{
  
      
    
    const initialData = async()=>{
      var units =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/`);
       var unitsFiltered = units.data.rows.filter(e => e.deployedScene && e.deployedScene.id !== null);
    
      
      for(let i=0; i<unitsFiltered.length; i++){
          var data = unitsFiltered[i];
          console.log(data);
          var activeScene =  await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/${unitsFiltered[i].deployedScene.id}`);
          Object.assign(data, {sceneDataNew:activeScene.data});
    
          newUnitSceneData.push(data);
          
      }

      setLoading(false);
   
    }
    if(newUnitSceneData && newUnitSceneData.length<1){
      initialData();
    }
    
    },[newUnitSceneData,loading]) 
  

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

    return(
        <>

<Row >
        <div style={{position:"fixed",background:"#161616",zIndex:"100",width:"100vw"}}>
        <img src = "./infilogo.png" width="100px"style={{left:"4%",position:"relative"}} /> 
        </div >
        <br/><br/>
</Row>
<br/><br/>
<br/><br/>
   {!loading?(

<DiscoverComponent newUnitSceneData={newUnitSceneData}/>
   ):(<Spin style={{marginTop:"15%",marginLeft:"45%",marginRight:"45%"}} />)}
           

       
        </>
    )

}

export default HomePage;
